<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Total objects -->
          <b-col sm="auto">
            <h3 class="font-weight-bolder">
              Sorteos
              <b-badge
                v-if="rafflesCount"
                variant="dark"
              >
                {{ rafflesCount }}
              </b-badge>
            </h3>
          </b-col>
          <!-- Search -->
          <b-col
            sm="auto"
            class="d-flex justify-content-between"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-button-group
              size="sm"
            >
              <b-button
                variant="primary"
                @click="filterActiveStatus = true"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
              <b-button
                variant="primary"
                @click="filterActiveStatus = false"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-button-group>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-button
              variant="primary"
              :to=" {name: 'CreateRaffle'} "
            >
              <span class="text-nowrap">Añadir sorteo nuevo</span>
            </b-button>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="raffles"
        responsive
        :fields="fields"
        primary-key="id"
        show-empty
        empty-text="No se han ceado ningún sorteo aún"
      >

        <!-- Column: Raffle data -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="45"
                :class="data.item.active_status ? 'border-success' : 'border-danger'"
                class="circle"
                :src="data.item.thumbnails[0] || data.item.images[0] "
                :to="{ name: 'raffle', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'raffle', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
              <div>
                <b-badge
                  v-for="category in data.item.categories"
                  :key="category.id"
                  pill
                  variant="primary"
                  class="text-capitalize badge-text mr-50"
                >
                  {{ category.name }}
                </b-badge>
              </div>
            </b-link>
          </b-media>
        </template>

        <!-- Column: Raffle Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="(data.item.is_published === true) ? 'success' : 'warning'"
          >
            {{ data.item.is_published | raffle_status }}
          </b-badge>
        </template>

        <!-- Column: Raffle Date -->
        <template #cell(raffle_date)="data">
          <div :class="new Date() > new Date(data.item.raffle_date) ? 'text-warning' : 'text-regular'">
            {{ data.item.raffle_date | dateNtime }}
          </div>
          <div>
            <i class="fas fa-clock" />
            {{ data.item.raffle_date | time }}
          </div>
        </template>

        <!-- Column: Is Banner -->
        <template #cell(banner)="data">
          <b-form-checkbox
            v-model="data.item.is_bannered"
            @change="updateRaffle(data.item)"
          />
        </template>

        <!-- Column: Is Featured -->
        <template #cell(featured)="data">
          <b-form-checkbox
            v-model="data.item.is_featured"
            @change="updateRaffle(data.item)"
          />
        </template>

        <!-- Column: Info -->
        <template #cell(raffle_info)="data">
          <i class="fas fa-ticket-alt" /> {{ data.item.available_tickets }}/{{ data.item.number_of_tickets }}
          <div>
            <i class="fas fa-coins" />  {{ data.item.price_of_ticket }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'raffle', params: { id: data.item.id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Ver sorteo</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="data.item.is_published === false && ( new Date() < new Date(data.item.raffle_date) )"
              @click="showPublishConfirmationModal(data.item)"
            >
              <feather-icon icon="SendIcon" />
              <span class="align-middle ml-50">Publicar sorteo</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="data.item.is_finished === true && ( new Date() >= new Date(data.item.raffle_date) ) && !data.item.winner_ticket"
              :to="{ name: 'register-winner', params: { raffle_id: data.item.id } }"
            >
              <feather-icon icon="UserCheckIcon" />
              <span class="align-middle ml-50">Publicar ganador</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="data.item.is_published === false"
              :to="{ name: 'RaffleEditForm', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar sorteo</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="data.item.is_published === false"
              @click="showDeleteConfirmationModal(data.item)"
            >
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Eliminar sorteo</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row v-if="pagination && rafflesCount">
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ pagination.total_objects }} de {{ rafflesCount }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="pagination.page"
              :total-rows="pagination.total_objects"
              :per-page="pagination.per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debounce } from 'lodash'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  data() {
    return {
      searchQuery: null,
      filterActiveStatus: true,
      is_banner: false,
      is_featured: false,
      fields: [
        {
          key: 'name',
          label: 'Nombre',
        },
        {
          key: 'status',
          label: 'Estado',
        },
        {
          key: 'raffle_date',
          label: 'Finaliza',
        },
        {
          key: 'banner',
          label: 'Banner',
        },
        {
          key: 'featured',
          label: 'Featured',
        },
        {
          key: 'raffle_info',
          label: 'info',
        },
        {
          key: 'actions',
          label: 'Herramientas',
        },
      ],
      dataMeta: {
        from: 100,
        to: 200,
        of: 5,
      },
    }
  },
  computed: {
    ...mapGetters('raffles', ['raffles', 'pagination', 'rafflesCount']),
  },
  watch: {
    filterActiveStatus(newValue) {
      this.getRaffles({ by_active_status: newValue })
    },
    searchQuery: debounce(function searchQuery(query) {
      this.getRaffles({ by_name: query })
    }, 250),
  },
  created() {
    this.getRaffles({ by_active_status: true })
  },
  methods: {
    ...mapActions('raffles', ['getRaffles', 'publishRaffle', 'updateRafflePriority']),
    updateRaffle(raffle) {
      this.updateRafflePriority(raffle)
        .then(() => this.getRaffles({ by_active_status: true }))
        .catch(() => !raffle.is_bannered, !raffle.is_featured)
    },
    showPublishConfirmationModal(raffle) {
      this.$bvModal.msgBoxConfirm('Porfavor confirma que deseas publicar el sorteo actual', {
        title: 'Mensaje de confirmación',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'SÍ',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value === true) {
            this.publishRaffle(raffle)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Sorteo publicado!',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              })
          }
        })
    },
    showDeleteConfirmationModal(raffle) {
      this.$bvModal.msgBoxConfirm('Porfavor confirma que deseas eliminar el sorteo actual', {
        title: 'Mensaje de confirmación',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'SÍ',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value === true) {
            const raffleToDelete = {
              ...raffle,
              active_status: false,
            }
            this.updateRafflePriority(raffleToDelete)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Sorteo eliminado',
                    icon: 'Trash2Icon',
                    variant: 'success',
                  },
                })
                this.getRaffles({ by_active_status: true })
              })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.badge-text {
  font-size: 10px;
}
.circle {
  border-radius: 10px solid !important;
}
.border-success {
  border-color: #2dce89;
}
.border-danger {
  border-color: #f5365c;
}
</style>
